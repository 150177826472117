import React, { Component } from 'react'
import FormLocation from '../../shared/FormLocation'
import FormSearch from '../../shared/FormSearch'
import FormFilters from '../../shared/FormFilters'
import Button from '../../shared/Button'

export default class FiltersHeader extends Component {
  state = {
    isActive: '',
  }

  setActiveField = f => {
    this.setState({ isActive: f })
  }

  render() {
    const { isMMS, handleSearch, filters, onFilterChange, nameOnly } = this.props
    const { isActive } = this.state

    return (
      <div className={`pw FiltersHeader ${isActive.length ? 'active' : ''} ${nameOnly ? 'name-only' : ''}`}>
        <form className={`FiltersHeader--form`} onSubmit={handleSearch}>
          {isMMS ? (
            <div className={`Form--field Flex--2 ${isActive === 'search' ? 'active' : ''}`}>
              <FormSearch
                isActive={isActive === 'search'}
                setActive={this.setActiveField}
                query={filters.query}
                onFilterChange={onFilterChange}
                isMMS={isMMS}
              />
            </div>
          ) : null}
          {isMMS ? (
            <div className={`Form--field Flex--2 ${isActive === 'location' ? 'active' : ''}`}>
              <FormLocation
                isActive={isActive === 'location'}
                setActive={this.setActiveField}
                locationDisplayName={filters.locationDisplayName}
                onFilterChange={onFilterChange}
              />
            </div>
          ) : null}
          <div className={`Form--field Flex--1 ${isActive === 'filters' ? 'active' : ''}`}>
            <FormFilters
              isActive={isActive === 'filters'}
              setActive={this.setActiveField}
              isMMS={isMMS}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </div>
          <div className={`Form--submit ${isActive.length ? 'active' : ''}`}>
            <Button
              className="Button Button--gold"
              aria-label="Search"
              type="submit"
              disabled={!isMMS && filters && !filters.name.length}
            >
              <i className="Icon Icon--search"></i>
              <span>Search for {isMMS ? 'Guides' : 'Coaches'}</span>
            </Button>
          </div>
        </form>
      </div>
    )
  }
}
