import axios from 'axios'
import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router'
import Slider from 'react-slick'
import { disableChat } from '../../../utility/chat'
import { Contact, Testimonial } from '../components'

// Import css files
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const profilePageLoadEventArguments = {
  dataLayer: { event: 'loadProfilePage' },
  dataLayerName: 'CYMDataLayer',
}

class Profile extends Component {
  constructor(props) {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
    }

    super(props)
    this.state = {
      profile: props.profile ? props.profile : null,
      referral_code: null,
      noMatch: false,
      fixAside: false,
    }
    this.contactForm = React.createRef()

    this.slider = React.createRef()
    if (props.modalProfileId) {
      // this.loadProfile(props.modalProfileId)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    } else {
      if (!props.isMMS) {
        this.loadReferral(props.match.params.slug)
      }
      this.loadProfile(props.match.params.slug)
    }

    TagManager.dataLayer(profilePageLoadEventArguments)
    disableChat()
  }

  handleResize = () => {
    const fixAside = window.innerWidth >= 1024
    if (this.state.fixAside !== fixAside) this.setState({ fixAside })
  }

  componentDidMount() {
    let profileSlug = this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
    axios.post(process.env.REACT_APP_API_LOCATION + `/api/profile-view/${profileSlug}`)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalProfileId) {
      return
    }
    let {
      location: { pathname },
    } = this.props

    if (prevProps.location.pathname === pathname) return
    if (!this.props.isMMS) {
      this.loadReferral(this.props.match.params.slug)
    }
    this.loadProfile(this.props.match.params.slug)
  }

  trackWebsiteClick = () => {
    axios.post(
      process.env.REACT_APP_API_LOCATION +
        `/api/external-website-view/${
          this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
        }`
    )
  }

  loadProfile = slug => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/profiles?slug=' + encodeURIComponent(slug))
      .then(response => {
        if (response.data.total) {
          this.setState({ profile: response.data.data[0] })
        } else {
          this.setState({ noMatch: true })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadReferral = slug => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + `/api/profile/referral/${encodeURIComponent(slug)}`)
      .then(({ data, status }) => {
        if (status === 200 && !!data.success) {
          this.setState({ referral_code: data.referral_code })
        }
      })
  }

  render() {
    const { profile, referral_code } = this.state
    const { isMMS, modalProfileId } = this.props

    if (this.state.noMatch) {
      return <Redirect to="/404" />
    }

    let averageCost = ''
    if (profile) {
      switch (profile.price) {
        case 1:
          averageCost = '$500'
          break
        case 2:
          averageCost = '$1,500'
          break
        case 3:
          averageCost = '$5,000'
          break
        case 4:
          averageCost = '$10,000'
          break
        default:
          averageCost = '$10,000'
      }
    }

    if (!this.state.profile) {
      return (
        <div className="flex items-center justify-center h-banner Profile">
          <div className="flex flex-col items-center">
            <span className="spinner"></span>
            <span className="block mt-6 font-bold tracking-widest uppercase text-md">Loading profile...</span>
          </div>
        </div>
      )
    }

    const renderMMS = () => {
      return (
        <div className="Profile">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {'Marketing Made Simple'} | {profile.first_name} {profile.last_name}
            </title>
          </Helmet>
          <section className="mb-12 lg:mb-24">
            <div className="3xl:w-8/12 2xl:mx-auto mx-none w-full">
              <img
                className="object-fit w-full max-h-96"
                alt="Custom Profile Image"
                src={
                  profile.hero_image_url ? profile.hero_image_url : require('../../../assets/hero-image.jpg').default
                }
              />
            </div>
            <div className="hidden px-6 py-4 bg-white border-b border-gray-200 w-100 lg:block">
              <div className="relative grid grid-cols-3 mx-auto max-w-7xl">
                <div className="absolute left-0 w-48 h-48 overflow-hidden transform -translate-y-1/2 border border-gray-100 border-solid rounded-full shadow-sm top-1/2">
                  <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                </div>
                <div className="relative flex items-center justify-center col-span-2 mb-0 text-base uppercase lg:justify-start lg:pl-52">
                  <img className="w-16 h-16 mr-4" src={require('../../../assets/guide-badge-2020.svg').default} />
                  {profile?.topCertification?.name === 'agency' ||
                  profile?.workshopCertified?.name === 'workshop facilitator' ? (
                    <div className="relative flex flex-col items-start mr-4">
                      {profile?.topCertification?.name === 'agency' && (
                        <span className="pill">
                          Agency
                          <div className="tooltip">
                            This entire team has been trained, so no matter who you talk to at their agency you'll be in
                            good hands! If you are a larger company looking for a full-suite agency, look no further
                            than one of ours!
                          </div>
                        </span>
                      )}
                      {profile?.workshopCertified?.name === 'workshop facilitator' && (
                        <span className="pill">
                          Facilitator
                          <div className="tooltip">
                            This Guide is equipped to put on a StoryBrand Private Workshop for your team. It's perfect
                            if you need to get everyone on the same page before moving forward with a big marketing
                            effort.
                          </div>
                        </span>
                      )}
                    </div>
                  ) : null}
                  <span className="inline-block mr-2 font-bold">
                    StoryBrand <span className="font-light">Certified</span> Guide
                  </span>
                </div>
                {/* <div className="items-center justify-center hidden col-span-1 text-center lg:flex">
                  <div className="relative">
                    <label className={`price price--${profile.price}`}>
                      {Array.from({ length: 4 }).map(v => (
                        <span></span>
                      ))}
                    </label>
                    <span className="tooltip">Projects starting at {averageCost}</span>
                    <span className="block mt-2 text-xs font-bold uppercase">Average Cost</span>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <section className="px-6 mb-12 md:px-10">
            <div className="grid grid-cols-1 mx-auto lg:grid-cols-3 max-w-7xl lg:gap-x-6">
              <aside className="block col-span-1 mb-12 lg:mb-0">
                <div className="grid items-center w-full grid-cols-4 mb-4 text-center gap-y-4 sm:gap-y-0 sm:gap-x-1 lg:hidden">
                  <div className="justify-center order-2 col-span-4 sm:col-span-1 sm:order-1">
                    {profile?.topCertification?.name === 'agency' ||
                    profile?.workshopCertified?.name === 'workshop facilitator' ? (
                      <div className="relative flex flex-col items-center lg:items-start">
                        {profile?.topCertification?.name === 'agency' && (
                          <span className="pill">
                            Agency
                            <div className="tooltip">
                              This entire team has been trained, so no matter who you talk to at their agency you'll be
                              in good hands! If you are a larger company looking for a full-suite agency, look no
                              further than one of ours!
                            </div>
                          </span>
                        )}
                        {profile?.workshopCertified?.name === 'workshop facilitator' && (
                          <span className="pill">
                            Facilitator
                            <div className="tooltip">
                              This Guide is equipped to put on a StoryBrand Private Workshop for your team. It's perfect
                              if you need to get everyone on the same page before moving forward with a big marketing
                              effort.
                            </div>
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="order-1 col-span-4 sm:col-span-2 sm:order-2">
                    <div className="flex flex-col items-center">
                      <div className="relative flex justify-center mb-4">
                        <div className="w-48 h-48 overflow-hidden border border-gray-100 border-solid rounded-full shadow-sm">
                          <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                        </div>
                        <img
                          className="absolute left-0 w-16 h-16 transform -translate-x-1/2 -translate-y-1/2 top-1/2"
                          src={require('../../../assets/guide-badge-2020.svg').default}
                        />
                      </div>
                      <p className="text-sm uppercase">
                        <span className="inline-block mr-2 font-bold">
                          StoryBrand <span className="font-light">Certified</span> Guide
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <div className="order-3 col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center text-center lg:hidden">
                      <div className="relative">
                        <label className={`price price--${profile.price}`}>
                          {Array.from({ length: 4 }).map(v => (
                            <span></span>
                          ))}
                        </label>
                        <span className="tooltip">Projects starting at {averageCost}</span>
                        <span className="block mt-2 text-xs font-bold uppercase">Average Cost</span>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="grid grid-cols-2 text-center gap-x-4 lg:block lg:text-left">
                  <div className="col-span-2">
                    <h1 className="text-3xl font-bold text-center lg:text-left">
                      {profile.first_name} {profile.last_name}
                    </h1>
                    <p className="mb-0 text-lg italic">{profile.location}</p>
                    <p className="text-base font-bold uppercase text-gold">
                      {profile.certification_date &&
                        'Certified since ' + new Date(profile.certification_date).getFullYear()}
                    </p>
                  </div>
                  <div className="col-span-2 my-8">
                    {!!!modalProfileId && (
                      <div>
                        <a
                          name="contact"
                          className="btn btn--primary"
                          role="button"
                          onClick={() => {
                            window.scrollTo({ top: this.contactForm.current.offsetTop, behavior: 'smooth' })
                          }}
                        >
                          Contact Me
                        </a>
                      </div>
                    )}
                    {!!profile.website && (
                      <div className="mt-3">
                        <a
                          className="btn btn--primary-ghost"
                          href={profile.website.indexOf('://') === -1 ? 'http://' + profile.website : profile.website}
                          onClick={this.trackWebsiteClick}
                          target="_blank"
                        >
                          Visit Website
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 mb-8 sm:col-span-1">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Skills
                    </h3>
                    <ul>
                      {profile.skills.map((skill, i) => (
                        <li key={i}>{skill.name}</li>
                      ))}
                    </ul>
                  </div>
                  {!!profile.industry && (
                    <div className="col-span-2 sm:col-span-1">
                      <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                        Areas of Focus
                      </h3>
                      <p className="text-md">{profile.industry}</p>
                    </div>
                  )}
                </div>
              </aside>
              <div className="col-span-2 content">
                <section className="mb-8">
                  <div className="flex items-end justify-between pb-2 mb-4">
                    <h3 className="text-lg tracking-widest uppercase">Portfolio</h3>
                    <div>
                      <a className="Arrow Arrow--left" onClick={() => this.slider.current.slickPrev()}>
                        <span>&lsaquo;</span>
                      </a>
                      <a className="Arrow Arrow--right" onClick={() => this.slider.current.slickNext()}>
                        <span>&rsaquo;</span>
                      </a>
                    </div>
                  </div>
                  <div className="mb-8">
                    <Slider arrows={false} ref={this.slider}>
                      {profile.portfolio.map((project, i) => (
                        <div className="item" key={i}>
                          {project.image_url ? (
                            <div className="">
                              <img className="object-cover" alt="Custom Project Image" src={project.image_url} />
                            </div>
                          ) : (
                            <div className="itemPlaceholder">{project.client_name ? project.client_name : null}</div>
                          )}
                          <div className="grid grid-cols-5">
                            {project.client_name || project.external_link ? (
                              <div className="col-span-5 p-8 bg-white sm:col-span-2">
                                {project.client_name ? (
                                  <h5 className="mb-1 tracking-widest uppercase">Client</h5>
                                ) : null}
                                {project.client_name ? (
                                  <h4 className="font-bold text-left">{project.client_name}</h4>
                                ) : null}
                                {project.external_link ? (
                                  <h5 className="mt-6 mb-1 tracking-widest uppercase">Related Media</h5>
                                ) : null}
                                {project.external_link ? (
                                  <a
                                    className="block overflow-hidden overflow-ellipsis text-gold whitespace-nowrap"
                                    href={
                                      project.external_link.indexOf('://') === -1
                                        ? 'http://' + project.external_link
                                        : project.external_link
                                    }
                                    target="_blank"
                                  >
                                    {project.external_link.indexOf('://') === -1
                                      ? 'http://' + project.external_link
                                      : project.external_link}
                                  </a>
                                ) : null}
                              </div>
                            ) : null}
                            <div className="col-span-5 p-8 bg-gray-100 sm:col-span-3 whitespace-pre-line">
                              <p className="mb-0 text-md">{project.project_description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </section>
                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    About
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: profile.pitch }} />
                </section>
                {profile.testimonies && profile.testimonies.length ? (
                  <section className="mb-16">
                    <h3 className="mb-6 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Testimonials
                    </h3>
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                      {profile.testimonies
                        .sort((a, b) => +a.sort - +b.sort)
                        .map((testimony, i) => (
                          <div>
                            <Testimonial key={i} testimony={testimony} />
                          </div>
                        ))}
                    </div>
                  </section>
                ) : null}
                {modalProfileId ? null : (
                  <section className="mb-16" ref={this.contactForm}>
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Contact Me
                    </h3>
                    <Contact profile={profile} isMMS={isMMS} />
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
      )
    }

    const renderHAC = () => {
      return (
        <div className="Profile">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {'Hire A Coach'} | {profile.first_name} {profile.last_name}
            </title>
          </Helmet>
          <section className="mb-12 lg:mb-24">
            <div className="w-100">
              <img
                className="object-fit w-full max-h-96"
                alt="Custom Profile Image"
                src={
                  profile.hero_image_url
                    ? profile.hero_image_url
                    : require('../../../assets/coach-hero-image.jpg').default
                }
              />
            </div>
            <div className="hidden px-6 py-4 bg-white border-b border-gray-200 w-100 lg:block">
              <div className="relative mx-auto max-w-7xl">
                <div className="absolute left-0 w-48 h-48 overflow-hidden transform -translate-y-1/2 border border-gray-100 border-solid rounded-full shadow-sm top-1/2">
                  <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                </div>
                <p className="relative flex items-center justify-center mb-0 text-base uppercase lg:justify-start lg:pl-52">
                  <img className="w-16 h-16 mr-4" src={require('../../../assets/coach-badge-2020.svg').default} />
                  <span className="inline-block mr-2 font-bold">Business Made Simple</span>
                  <span>Certified Coach</span>
                </p>
              </div>
            </div>
          </section>
          <section className="px-6 mb-12 md:px-10">
            <div className="grid grid-cols-1 mx-auto lg:grid-cols-3 max-w-7xl lg:gap-x-6">
              <aside className="flex flex-col-reverse items-stretch justify-between col-span-1 mb-12 md:flex-row lg:block lg:mb-0">
                <div className="flex-shrink-0 mr-4">
                  <div className="text-center md:text-left">
                    <div>
                      <h4 className="text-3xl font-bold text-center md:text-left">
                        {profile.first_name} {profile.last_name}
                      </h4>
                      <p className="mb-0 text-lg italic">{profile.location}</p>
                      <p className="text-base font-bold uppercase text-gold">
                        {!!profile.certification_date &&
                          'Certified since ' + new Date(profile.certification_date).getFullYear()}
                      </p>
                    </div>
                    <div className="my-8">
                      {!!!modalProfileId && (
                        <div>
                          <a
                            name="contact"
                            className="btn btn--primary"
                            role="button"
                            onClick={() => {
                              window.scrollTo({ top: this.contactForm.current.offsetTop, behavior: 'smooth' })
                            }}
                          >
                            Contact Me
                          </a>
                        </div>
                      )}
                      {!!profile.website && (
                        <div className="mt-3">
                          <a
                            className="btn btn--primary-ghost"
                            href={profile.website.indexOf('://') === -1 ? 'http://' + profile.website : profile.website}
                            onClick={this.trackWebsiteClick}
                            target="_blank"
                          >
                            Visit Website
                          </a>
                        </div>
                      )}
                      {!!referral_code && (
                        <div className="mt-3">
                          <a
                            className="btn btn--primary"
                            target="_blank"
                            aria-label="external link to mybusinessreport.com"
                            href={`https://mybusinessreport.com/assessment?ref=${referral_code}`}
                          >
                            Take the Assessment
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  {profile.work_options && profile.work_options.length ? (
                    <div>
                      <h3 className="text-lg tracking-widest uppercase">Ways We Can Work Together</h3>
                      <ul>
                        {profile.work_options.map(item => (
                          <li className="flex items-center text-base">
                            <img
                              className="mr-2"
                              alt="Check Mark"
                              src={require('../../../assets/check-mark-green.svg').default}
                            />
                            <span className="text-black">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center justify-center w-full mb-4 text-center lg:hidden">
                  <div className="flex flex-col items-center">
                    <div className="relative flex justify-center mb-4">
                      <div className="w-48 h-48 overflow-hidden border border-gray-100 border-solid rounded-full shadow-sm">
                        <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                      </div>
                      <img
                        className="absolute left-0 w-16 h-16 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full top-1/2"
                        src={require('../../../assets/coach-badge-2020.svg').default}
                      />
                    </div>
                    <p className="text-sm uppercase">
                      <span className="block mr-2 font-bold">Business Made Simple</span>
                      <span>Certified Coach</span>
                    </p>
                  </div>
                </div>
              </aside>

              <div className="col-span-2 content">
                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    About
                  </h3>
                  <p className="text-base" dangerouslySetInnerHTML={{ __html: profile.pitch }} />
                </section>

                {profile.services && profile.services.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Services Offered
                    </h3>
                    <ul>
                      {profile.services.map((service, i) => (
                        <li key={i}>{service.name}</li>
                      ))}
                    </ul>
                  </section>
                ) : null}

                {profile.industries && profile.industries.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Industries
                    </h3>
                    <ul>
                      {profile.industries.map((ind, i) => (
                        <li key={i}>{ind.name}</li>
                      ))}
                    </ul>
                  </section>
                ) : null}

                {/* {profile.businessTypes && profile.businessTypes.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Business Types
                    </h3>
                    <ul>
                      {profile.businessTypes.map((b, i) => (
                        <li key={i} className={'businessTypes'}>
                          {b.name}
                        </li>
                      ))}
                    </ul>
                  </section>
                ) : null} */}

                {profile.testimonies && profile.testimonies.length ? (
                  <section className="mb-16">
                    <h3 className="mb-6 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Testimonials
                    </h3>
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                      {profile.testimonies
                        .sort((a, b) => +a.sort - +b.sort)
                        .map((testimony, i) => (
                          <div>
                            <Testimonial key={i} testimony={testimony} />
                          </div>
                        ))}
                    </div>
                  </section>
                ) : null}

                {modalProfileId ? null : (
                  <section className="mt-16" ref={this.contactForm}>
                    <h3 className="mb-4 text-lg tracking-widest uppercase">Contact Me</h3>
                    <Contact profile={profile} isMMS={isMMS} />
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
      )
    }

    return isMMS ? renderMMS() : renderHAC()
  }
}

export default Profile
