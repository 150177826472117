import React, { Fragment } from 'react'
import { Profile } from '../components'

const ProfileModal = ({ profile, isMMS, isSelectable, handleAndClose, profileToView, isSelected }) => {
    return (
        <Fragment>
            <div className="button-wrapper" data-app={isMMS ? 'mms' : 'hac'}>
                {isSelectable ? (
                    <div className="Card__select-coach">
                        <button
                            onClick={isSelectable ? () => handleAndClose(profile) : () => {}}
                            className="Button Button--gold"
                        >
                            {isSelected ? 'Remove This Coach' : 'Select This Coach'}
                        </button>
                    </div>
                ) : null}
            </div>
            <div className="modal-content" data-app={isMMS ? 'mms' : 'hac'}>
                <div className="profile-wrapper">
                    <Profile
                        isMMS={isMMS}
                        modalProfileId={profileToView ? profileToView.slug : profile.slug}
                        profile={profileToView ? profileToView : profile}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default ProfileModal
