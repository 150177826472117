import React, { useLayoutEffect, useRef } from 'react'

export default function Forms() {
  return <div>Forms</div>
}

export function Label({ large, bold = true, small, children, ...rest }) {
  return (
    <label
      style={{
        fontSize: large ? '16px' : '13px',
      }}
      {...rest}
      className={`text-gray-900 mb-2 ${bold ? 'font-bold ' : 'font-regular'}`}
    >
      {children}
    </label>
  )
}

export function Input({ shouldFocus = false, shouldClear, onFocus, ...rest }) {
  const ref = useRef()

  useLayoutEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus()
    }
  }, [ref, shouldFocus])

  return (
    <input
      {...rest}
      ref={ref}
      className="Input p-0 m-0 placeholder-gray-900 bg-transparent focus:outline-none focus:ring-none"
      onFocus={e => {
        // onFocus()
        //move to end of line
        //e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
      }}
    />
  )
}

export function Checkbox({ stateIdentifier, id, name, onChange, defaultChecked }) {
  return (
    <div className="flex flex-row justify-start items-center space-x-2">
      <input
        key={defaultChecked}
        className="w-6 h-6 form-checkbox accent-red-500 border border-gray-300 border-solid rounded text-black focus:ring-black"
        type="checkbox"
        id={`${stateIdentifier}-${id}`}
        name={`${stateIdentifier}-${id}`}
        onChange={() => {
          onChange(stateIdentifier, id)
        }}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={`${stateIdentifier}-${id}`} className="font-light text-md capitalize">
        {name}
      </label>
    </div>
  )
}
