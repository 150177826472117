import * as React from 'react'
import { Component } from 'react'
import { Link, useLocation } from 'react-router-dom'

function HACHeader({ calculateSearchLink, handleClose }) {
  //use the location to flip the find your coach button styles
  const location = useLocation()

  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'hac'}>
        <ul>
          <li className="bg-white rounded-l-full p-5" style={{ marginRight: 0 }}>
            <a
              style={{ color: '#2d3354', fontWeight: '800' }}
              href={'https://www.businessmadesimple.com/coaching-certification/'}
              target="_blank"
            >
              Become a coach
            </a>
          </li>
          <li className="bg-white p-5" style={{ marginRight: 0, color: '#2d3354' }}>
            <a
              style={{ color: '#2d3354', fontWeight: '800' }}
              href={process.env.REACT_APP_ADMIN_LOCATION + '/login'}
              target="_blank"
            >
              Login
            </a>
          </li>
          <li className="bg-white p-5 rounded-r-full">
            <Link
              style={{
                borderBottom: '',
                border: 'none',
                fontWeight: '700',
                marginRight: location.pathname === '/' ? '-11px' : '0px',
              }}
              to={calculateSearchLink()}
              onClick={handleClose}
              role="button"
              className={location.pathname === '/' ? 'goldBtn-pill p-4 rounded-full hover-none border-0' : 'largeBtn'}
            >
              Find Your Coach
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

function MMSHeader({ handleClose }) {
  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'mms'}>
        <ul>
          <li>
            <a href="https://storybrand.com/sales-funnel-plan/" target="_blank" rel="noopener noreferrer">
              Download <span>The</span> Sales Funnel <span>Plan</span>
            </a>
          </li>

          <li>
            <a href="https://storybrand.com/guide/" target="_blank" rel="noopener noreferrer">
              Get Certified
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_ADMIN_LOCATION + '/login'} target="_blank" rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li>
            <Link to="/search" onClick={handleClose} role="button">
              Search <span>Now</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburgered: false,
    }
  }

  handleToggle = () => {
    this.setState({ hamburgered: !this.state.hamburgered })
  }

  handleClose = () => {
    this.setState({ hamburgered: false })
  }

  render() {
    const { isMMS } = this.props

    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <header className={'cf ' + (this.state.hamburgered ? 'hamburgered' : '')}>
        <div data-st="st-row" className="pw menu-wrapper">
          <div data-st="st-six" className="nav-left">
            <Link to="/">
              <div className="logo" data-app={isMMS ? 'mms' : 'hac'}></div>
            </Link>
            <a href="#" id="toggle" onClick={this.handleToggle}>
              <div className="patty" data-app={isMMS ? 'mms' : 'hac'}></div>
            </a>
          </div>
          {isMMS ? (
            <MMSHeader handleClose={this.handleClose} />
          ) : (
            <HACHeader handleClose={this.handleClose} calculateSearchLink={calculateSearchLink} />
          )}
        </div>
      </header>
    )
  }
}

export default Header

//TODO:darken gradient
