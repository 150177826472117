import axios from 'axios'
import Cookies from 'js-cookie'
import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { StickyContainer } from 'react-sticky'
import VisibilitySensor from 'react-visibility-sensor'
import { v4 as uuidv4 } from 'uuid'
import { enableChat } from '../../../utility/chat'
import Button from '../../shared/Button'
import { GuidedSearch } from '../components'
import Card from '../components/Card'

const searchEventArguments = {
  dataLayer: { event: 'searchEvent' },
  dataLayerName: 'CYMDataLayer',
}

class SearchHAC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextPage: null,
      prevPage: null,
      modalOpen: false,
      profiles: [],
      moreProfiles: [],
      hasLoadedAllSearchMatches: false,
      loading: true,
      displayOpen: false,
      allSkills: [],
      allWorkOptions: [],
      allServices: [],
      allCerts: [],
      selectedContacts: [],
      previouslySelectedContacts: [],
      batch: null,
      alreadyUsedCoachConsult: false,
    }
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true })
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }
    enableChat()
  }

  componentDidMount = () => {
    const alreadyUsedCoachConsult = Cookies.get('sb-hac-cc2')

    if (this.props.isMMS && this.props.coachConsult) {
      this.props.router.push('/search')
    }

    if (this.props.coachConsult && alreadyUsedCoachConsult) {
      this.setState({ alreadyUsedCoachConsult })
    }

    this.props.setFooterVisibility(false)
    this.setState({ batch: uuidv4() })
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.coachConsult != this.props.coachConsult) {
      if (!this.props.coachConsult) {
        this.setState({ alreadyUsedCoachConsult: false })
      }
    }
  }

  componentWillUnmount = () => {
    this.props.setFooterVisibility(true)
  }

  onOpenModal = e => {
    e.stopPropagation()
    this.setState({ modalOpen: true })
  }
  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  handleSearch = e => {
    e && e.preventDefault()
    this.reset(() => this.loadProfiles())
  }

  queryString = params =>
    Object.keys(params)
      .filter(key => {
        return (
          !!params[key] && !(Array.isArray(params[key]) && (params[key].length === 0 || params[key].includes('all')))
        )
      })
      .filter(key => {
        if (key === 'work_options') {
          return (
            !!params[key] &&
            !(Array.isArray(params[key]) && params[key].length === this.props.filters.allWorkOptions.length)
          )
        } else {
          return true
        }
      })
      .map(key => key + '=' + params[key])
      .join('&')

  getFilters = () => {
    const {
      showFooter,
      locationDisplayName,
      locationAttempted,
      allSkills,
      allCerts,
      allBusinessTypes,
      allCoursePreferences,
      allIndustries,
      allServices,
      allWorkOptions,
      name,
      ...filters
    } = this.props.filters
    return { limit: 4, batch: this.state.batch, ...filters }
  }

  getSearchUrl = () => {
    let filters = this.getFilters()

    const queryString = this.queryString(filters)
    return `${process.env.REACT_APP_API_LOCATION}/api/profiles?${queryString}`
  }

  loadProfiles = (url = this.getSearchUrl(), filters = false, combine = false) => {
    const { coachConsult } = this.props

    url = !filters ? url : url + `&${this.queryString(filters)}`
    if (coachConsult) {
      url = url + '&coachConsult=1'
    }

    this.setState({ loading: true })
    axios.get(url).then(response => {
      TagManager.dataLayer(searchEventArguments)
      this.handleResults(response.data, combine)
      this.setState({ loading: false })
    })
  }

  handleResults({ next_page_url, data, first_page_url, prev_page_url }, combine = false) {
    const newState = {
      profiles: this.state.profiles,
      moreProfiles: this.state.moreProfiles,
      nextPage: next_page_url,
      prevPage: prev_page_url,
    }

    if (combine) {
      newState.profiles = newState.profiles.concat(data)
    } else {
      newState.profiles = data
    }

    this.setState(newState)
  }

  handleFiltersChanged = async filtersObj => {
    for (const key of Object.keys(filtersObj)) {
      await this.props.onFilterChange(key, filtersObj[key])
    }
  }

  setPreviouslySelectedContacts = () => {
    const { selectedContacts } = this.state
    this.setState({ previouslySelectedContacts: selectedContacts })
  }

  handleAutoload = isVisible => isVisible && this.loadProfiles(this.state.nextPage, this.getFilters())

  handleNextPage = (combine = false) => this.loadProfiles(this.state.nextPage, this.getFilters(), combine)
  handlePrevPage = () => this.loadProfiles(this.state.prevPage, this.getFilters())

  handleSelectedContact = profile => {
    const { coachConsult } = this.props
    const { selectedContacts, previouslySelectedContacts } = this.state

    let limit = 9999
    if (coachConsult) {
      limit = 1
    }

    let contacts = [...selectedContacts]

    const index = Object.keys(contacts).find(key => contacts[key].id === profile.id)
    if (index > -1) {
      contacts.splice(index, 1)
    } else {
      if (selectedContacts.length >= limit || previouslySelectedContacts.length >= limit) {
        return
      } else {
        contacts.push(profile)
      }
    }

    this.setState({ selectedContacts: contacts })
  }

  reset(callback) {
    this.setState(
      {
        hasLoadedAllSearchMatches: false,
        profiles: [],
        moreProfiles: [],
      },
      callback
    )
  }

  render() {
    const { loading, alreadyUsedCoachConsult } = this.state
    const { isMMS, filters, onFilterChange, nameOnly, router } = this.props

    return (
      <div className="AppWrapper" id="Search">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{isMMS ? 'Marketing Made Simple' : 'Hire A Coach'} | Provider Search Results</title>
        </Helmet>
        <StickyContainer>
          {alreadyUsedCoachConsult ? (
            <div id="Guided-Search" className="already-used">
              <div className="pw Guided-search-inner">
                <h2>You have already redeemed your ONE free Coach Consultation</h2>
                {/* <h2>
                                    The bonus has expired. To hire a Business Made Simple Certified Coach, head to{' '}
                                    <Link to="/" className="back-to-hac-link">
                                        HireACoach.com
                                    </Link>
                                </h2> */}
                <Button className="Button Button--gold" onClick={() => this.props.router.push('/')}>
                  Home
                </Button>
              </div>
            </div>
          ) : (
            <GuidedSearch
              nameOnly={nameOnly}
              filters={filters}
              onFilterChange={onFilterChange}
              handleSearch={this.handleSearch}
              loadProfiles={this.handleSearch}
              renderProfiles={this.renderProfiles}
              renderLoadingIndicator={this.renderLoadingIndicator}
              handleNext={this.handleNextPage}
              handlePrev={this.handlePrevPage}
              handleSelectedContact={this.handleSelectedContact}
              selectedContacts={this.state.selectedContacts}
              previouslySelectedContacts={this.state.previouslySelectedContacts}
              setPreviouslySelectedContacts={this.setPreviouslySelectedContacts}
              nextPage={this.state.nextPage}
              prevPage={this.state.prevPage}
              isMMS={this.props.isMMS}
              batch={this.state.batch}
              loading={loading}
              coachConsult={this.props.coachConsult}
              router={router}
              isAdmin={this.props.isAdmin}
            />
          )}
        </StickyContainer>
      </div>
    )
  }

  renderLoadingIndicator = () => (
    <div className="loading pw">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )

  renderAutoLoader() {
    if (this.state.loading) return this.renderLoadingIndicator()
    if (!this.state.nextPage) return null
    return (
      <VisibilitySensor onChange={this.handleAutoload}>
        <div>&nbsp;</div>
      </VisibilitySensor>
    )
  }

  renderProfiles = () => {
    const hasResults = this.state.profiles.length > 0
    const doneLoading = this.state.hasLoadedAllSearchMatches
    const zero = doneLoading && !hasResults
    const { nextPage, profiles, loading } = this.state

    if (zero)
      return (
        <div className="zero-count">
          <div className="zero-count__title">
            <span className="pop">0 experts</span> match your criteria
          </div>
          <div className="zero-count__copy">Use the toolbar above to refine your search</div>
        </div>
      )

    return (
      <div className="Cards" style={{ minWidth: '100%' }}>
        {profiles.map(profile => (
          <Card
            profile={profile}
            key={profile.id}
            isMMS={this.props.isMMS}
            isSelectable={true}
            handleSelectedContact={this.handleSelectedContact}
            selectedContacts={this.state.selectedContacts}
            inProfileModal={true}
          />
        ))}
        {!nextPage && !profiles.length && !loading ? (
          <div className={`Card--end-of-results ${profiles.length ? '' : 'Card--end-of-results__empty'}`}>
            There are no {profiles.length ? 'more ' : ' '}coaches who match your criteria
          </div>
        ) : null}
      </div>
    )
  }

  renderMoreProfiles() {
    if (this.state.moreProfiles.length === 0) return null
    return (
      <Fragment>
        <div className="Search__no-more">{`There are no more ${
          this.props.isMMS ? 'experts' : 'coaches'
        } matching your search`}</div>
        <div className="Search__separator">
          <span>Other Recommended {this.props.isMMS ? 'Experts' : 'Coaches'}</span>
        </div>
        <div className="Cards Cards--more">
          {this.state.moreProfiles.map(function (profile) {
            return <Card profile={profile} key={profile.id} />
          })}
        </div>
      </Fragment>
    )
  }
}

export default SearchHAC
