import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { enableChat } from '../../../utility/chat'

function Reason({ imageSource, title, subText }) {
  return (
    <div className="reason">
      <img src={imageSource} />
      <h3 className="text-xl">{title}</h3>
      <p>{subText}</p>
    </div>
  )
}
//
const homePageLoadEventArguments = {
  dataLayer: {
    event: 'homeHACPageLoad',
  },
  dataLayerName: 'CYMDataLayer',
}
const tagManagerArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'CYMDataLayer',
}

const waysColOne = [
  {
    bold: 'LEADERSHIP',
    text: ': Identify and prioritize 3 initiatives that will make your business more money. ',
    icon: 'leadership.svg',
    alt: 'Leadership',
  },
  {
    bold: 'SALES',
    text: ': Create a Sales conversation that will help you and your team confidently close more deals without being salesy.',
    icon: 'sales.svg',
    alt: 'Sales',
  },
  {
    bold: 'OPERATIONS',
    text: ': Install a management and productivity system that will keep your operation costs low and your productivity levels high.',
    icon: 'operations.svg',
    alt: 'Operations',
  },
  // {
  //   text: 'Clarify your message so customers listen',
  //   icon: 'clarify.svg',
  //   alt: 'Light Bulb',
  // },
  // {
  //   text: 'Help you run an effective execution plan',
  //   icon: 'help-you-run.svg',
  //   alt: 'Plan in action',
  // },
]

const waysColTwo = [
  {
    bold: 'MARKETING',
    text: ': Create a marketing funnel to generate consistent business and predictable cash flow.',
    icon: 'marketing.svg',
    alt: 'Marketing',
  },
  {
    bold: 'PRODUCTS',
    text: ': Perform profitability audits and install product briefs to ensure your product offering is highly lucrative.',
    icon: 'products.svg',
    alt: 'Product',
  },
  {
    bold: 'CASH FLOW',
    text: ': Learn to use 5 checking accounts so you can manage your finances with zero confusion or stress.',
    icon: 'cashflow.svg',
    alt: 'Funnel',
  },
  // {
  //   text: 'Become a value-driven professional',
  //   icon: 'value-driven.svg',
  //   alt: 'Valuable Person',
  // },
  // {
  //   text: 'Implement a sales framework that positions the customer as the hero',
  //   icon: 'handshake.svg',
  //   alt: 'Hand shake',
  // },
]

class HomeHAC extends Component {
  constructor(props) {
    super(props)

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    TagManager.initialize(tagManagerArguments)
    TagManager.dataLayer(homePageLoadEventArguments)
    enableChat()
  }

  onSkillClick = selectedSkill => {
    const skillId = this.props.filters.allSkills.filter(skill => selectedSkill === skill.name)
    if (skillId.length > 0) {
      this.props.onFilterChange('skills', [skillId[0].id.toString()])
    }
    this.handleSearch()
  }

  handleSearch = () => this.props.router.push('/search')

  componentDidMount() {
    this.props.onFilterChange('skills', [])
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('query', '')
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')
  }

  render() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode

    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <div className="Home hac">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hire A Coach | Home</title>
        </Helmet>
        <section className="hero">
          <div className="hero-wrapper">
            <div className="hero-image">
              <div className="white-polygon clip-paths" data-ie={isIE ? 'ie' : ''}>
                <div className="hero-content-triangle"></div>
              </div>
              <div className="white-triangle clip-paths">
                <div className="gold-triangle" data-ie={isIE ? 'ie' : ''}></div>
              </div>
              <div className="hero-content pw">
                <Link to="/">
                  <div className="logo"></div>
                </Link>
                <h1 className="text-black">
                  Business Coaching for <span>Busy Entrepreneurs</span>
                </h1>
                <h5 className="tagline">
                  Work less, make more, and spend more time doing the things you truly love – in your business and in
                  your life.
                </h5>
                <Link role="button" className="largeBtn cta md:mb-6 mb-2" to={calculateSearchLink()}>
                  Find Your Coach
                </Link>
                <Link
                  role="button"
                  className="Button--underline Button--underline__white Button--underline__small cta"
                  to={{
                    pathname: '/search',
                    state: { focusName: true },
                  }}
                >
                  Search By Name
                </Link>
              </div>
            </div>
            <div className="hero-content-bottom">
              <p>
                Most business owners are living with constant stress and overwhelm. That’s not sustainable. Our business
                coaches help you work smarter and faster so your business becomes your vehicle for freedom – not a
                burden.
              </p>
            </div>
            <div className="reasons" data-ie={isIE ? 'ie' : ''}>
              <Reason
                imageSource={require('../../../assets/1-find.svg').default}
                title={'1. Find your coach'}
                subText="Find the right Business Made Simple Coach for your needs and industry"
              />
              <Reason
                imageSource={require('../../../assets/2-hire.svg').default}
                title={'2. Hire your coach'}
                subText="Get mentored through our 6-step business growth plan"
              />
              <Reason
                imageSource={require('../../../assets/3-revenue.svg').default}
                title={'3. Grow your revenue & free time'}
                subText="Enjoy a more profitable business and more time to do what you love"
              />
            </div>
            {/* <div className="pw" data-st="st-row">
              <div data-st="st-four" className="">
                <div className="img-wrapper">
                  <img
                    src={require(`../../../assets/grow-your-business/1-location.svg`).default}
                    alt="Globe"
                    height="80"
                  />
                </div>
                <h3>1. Find your coach</h3>
                <p>Find the right Business Made Simple Coach for your needs and industry</p>
              </div>
              <div data-st="st-four" className="">
                <div className="img-wrapper">
                  <img
                    src={require(`../../../assets/grow-your-business/2-find-coach.svg`).default}
                    alt="People"
                    height="70"
                  />
                </div>
                <h3>2. Hire your coach</h3>
                <p>Get mentored through our 6-step business growth plan</p>
              </div>
              <div data-st="st-four" className="">
                <div className="img-wrapper">
                  <img
                    src={require(`../../../assets/grow-your-business/3-framework.svg`).default}
                    alt="Graph Trending Up"
                    height="90"
                  />
                </div>
                <h3>3. Grow your revenue & free time</h3>
                <p>Enjoy a more profitable business and more time to do what you love</p>
              </div>
            </div> */}
            {/* <div className="reasons" data-ie={isIE ? 'ie' : ''}>
              <div className="reason">
                <img src={require('../../../assets/1-optimize-biz.svg').default} alt="check mark" />
                <p>Optimize your business with our 6-step growth plan</p>
              </div>
              <div className="reason">
                <img src={require('../../../assets/2-revenue.svg').default} alt="check mark" />
                <p>Start generating predictable, recurring revenue</p>
              </div>
              <div className="reason">
                <img src={require('../../../assets/3-profitable-biz.svg').default} alt="check mark" />
                <p>Enjoy a profitable business and live a life you love</p>
              </div>
            </div> */}
          </div>
        </section>
        <section className="more-money">
          <div className="pw mb-0">
            <div data-st="st-twelve" className="heading">
              <h2>
                Your Coach Will Help You <br />
                <span>Execute a 6-Step Growth Plan</span>
                {/* 10 Ways a BMS Coach Will <br></br>
                <span>Make You More Money</span> */}
              </h2>
            </div>
            <h3 className="sub">
              Whether you need help with all 6 of these steps or just a few, our coaches can help.
            </h3>
            <div className="pw" data-st="st-row">
              <div className="ways-column" data-st="st-six">
                {waysColOne.map(item => {
                  return (
                    <div className="way" key={item.text}>
                      <img alt={item.alt} src={require(`../../../assets/ten-ways/${item.icon}`).default} />
                      <p>
                        <strong>{item.bold}</strong>
                        {item.text}
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className="ways-column two" data-st="st-six">
                {waysColTwo.map(item => {
                  return (
                    <div className="way" key={item.text}>
                      <img alt={item.alt} src={require(`../../../assets/ten-ways/${item.icon}`).default} />
                      <p>
                        <strong>{item.bold}</strong>
                        {item.text}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div data-st="st-twelve" className="mb-0">
              <Link role="button" className="largeBtn cta mb-0" to={calculateSearchLink()}>
                Find Your Coach
              </Link>
            </div>
          </div>
        </section>
        <section className="what-you-get">
          <div className="pw">
            <div data-st="st-seven" className="content">
              <h2>
                Entrepreneurs aren’t<br className="desktop-only"></br>
                always good operators.<br></br>
                <span>
                  That’s why you need a<br></br>
                  business coach.
                </span>
              </h2>
              <p>
                Entrepreneurs are risk-takers and visionaries. But once your business is up and running, you need to
                <strong> streamline your operation</strong> as soon as possible so you can get back to what you do best:
                <strong> being a visionary</strong>. Our coaches will help you do that.
              </p>
              <Link role="button" className="largeBtn cta" to={calculateSearchLink()}>
                Find Your Coach
              </Link>
            </div>
            <div data-st="st-five" className="image-right">
              <img src={require(`../../../assets/mba-section-image.png`).default} alt="Teacher Teaching Class" />
            </div>
          </div>
        </section>
        {/* <section className="grow">
          <div className="pw mb-0" data-st="st-row">
            <div data-st="st-twelve" className="heading">
              <h2>
                <span>Grow your business</span> with practical help from a Business Made Simple Coach.
              </h2>
            </div>
          </div>
          <div className="pw" data-st="st-row">
            <div data-st="st-four" className="">
              <div className="img-wrapper">
                <img
                  src={require(`../../../assets/grow-your-business/1-location.svg`).default}
                  alt="Globe"
                  height="80"
                />
              </div>
              <h3>1. Find your coach</h3>
              <p>Find the right Business Made Simple Coach for your needs and industry</p>
            </div>
            <div data-st="st-four" className="">
              <div className="img-wrapper">
                <img
                  src={require(`../../../assets/grow-your-business/2-find-coach.svg`).default}
                  alt="People"
                  height="70"
                />
              </div>
              <h3>2. Hire your coach</h3>
              <p>Get mentored through our 6-step business growth plan</p>
            </div>
            <div data-st="st-four" className="">
              <div className="img-wrapper">
                <img
                  src={require(`../../../assets/grow-your-business/3-framework.svg`).default}
                  alt="Graph Trending Up"
                  height="90"
                />
              </div>
              <h3>3. Grow your revenue & free time</h3>
              <p>Enjoy a more profitable business and more time to do what you love</p>
            </div>
          </div>
          <div className="pw cta" data-st="st-row">
            <div data-st="st-twelve">
              <Link role="button" className="largeBtn" to={calculateSearchLink()}>
                Find Your Coach
              </Link>
            </div>
          </div>
          <div className="gold-tab"> </div>
          <div className="bottom"></div>
        </section> */}
        <section className="with-a-coach">
          <div className="pw mb-0" data-st="st-row">
            <div data-st="st-twelve" className="heading">
              <h2>
                You don’t have to go through life burned out and stretched thin.{' '}
                <span>Our business coaches can help you…</span>
              </h2>
            </div>
          </div>
          <div className="pw mb-0" data-st="st-row">
            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Get out of the day-to-day</h3>
              </div>
            </div>
            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Get dependable, predictable results</h3>
              </div>
            </div>
            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Break revenue records</h3>
              </div>
            </div>

            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Put the right processes in place</h3>
              </div>
            </div>
            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Enjoy more free time and flexibility</h3>
              </div>
            </div>
            <div data-st="st-four" className="">
              <div className="reason">
                <img src={require('../../../assets/check-mark-blue.svg').default} alt="Check Mark" />
                <h3>Free up your schedule for passion projects</h3>
              </div>
            </div>
          </div>
          <div className="pw" data-st="st-row">
            <div data-st="st-twelve" className="mb-0">
              <Link role="button" className="largeBtn cta" to={calculateSearchLink()}>
                Find Your Coach
              </Link>
            </div>
          </div>
        </section>
        <section className="are-you-a-coach">
          <div className="pw">
            <div data-st="st-seven" className="content">
              <h2>Are you interested in becoming a Business Made Simple Business Coach?</h2>
              <p>If you want to be unstoppable as a business coach, you need three things:</p>
              <p>
                1. A system to scale your coaching business <br />
                2. A proven curriculum that delivers results to clients <br />
                3. A community of experts to share best practices with
              </p>
              <p>Get these and much more when you become a Business Made Simple Certified Coach. </p>
              <a
                role="button"
                className="largeBtn"
                href="https://www.businessmadesimple.com/coaching-certification/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apply Now
              </a>
            </div>
            <div data-st="st-five" className="image-right">
              <img src={require(`../../../assets/biz-coach-side-image.png`).default} alt="person" />
            </div>
          </div>
        </section>
      </div>
    )
  }
}
export default HomeHAC
